import React, { memo } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {
  Header,
  Heading,
  Footer,
  Paragraph,
  Row,
  Column,
  Anchor,
  Theme
} from "@lux/components-gomo";
import "@lux/components-gomo/build/lux-components.css";
import burgerDownloadApp from "./assets/images/image-burger-app.png";
import AppStoreBadge from "./assets/images/appstore-badge.png";
import GooglePlayBadge from "./assets/images/playstore-badge.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

const DownloadPanelImage = styled.img`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  top: ${remCalc(50)};
  z-index: -10;
  ${Theme.media.sm`
    max-width: 300px;
  `};

  ${Theme.media.md`
    max-width: 717px;
  `};
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  padding: 0 ${remCalc(24)}
`;

const BadgeImage = styled.img`
  padding: ${remCalc(10)};
  height: 40px;
`;

const AnchorButton = styled(Anchor)`
  display: inline-block;
`;

const StyledHeading = styled(Heading)`
  color: ${Theme.colours.gomo_black_33};
  text-align: center;
  margin: ${Theme.spacing.small} 0;
  font-size: 40px;
`;

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

declare var __PORTABLE_LUX__: boolean;

export function remCalc(
    px: number,
    base: number = 16,
    forcePortable: boolean = false
): string {
  if (
      (typeof __PORTABLE_LUX__ !== 'undefined' && __PORTABLE_LUX__) ||
      forcePortable
  ) {
    return px + 'px';
  } else {
    return px / base + 'rem';
  }
}
export function getPlatform(args):string{
  if (args.indexOf("iPhone") > -1) {
    return "ios";
  }  else if(args.indexOf("Android") > -1) {
    return "android";
  } else{
    return "others"
  }
}

export function getAppUri(url, platform):string{
  let newUri = "";

  if (url.indexOf("https://app.gomo.sg") > -1) {
    newUri = url.replace("https://app.gomo.sg/", "gomo://");
    if(platform === "android"){
      newUri = url.replace("https://app.gomo.sg", "gomo://app");
    }
  }  else if(url.indexOf("https://gomoui.uat3.test.aws.singtel.com") > -1) {
    newUri = url.replace("https://gomoui.uat3.test.aws.singtel.com/", "gomouat://");
    if(platform === "android"){
      newUri = url.replace("https://gomoui.uat3.test.aws.singtel.com", "gomouat://app");
    }
  } else{
    newUri = url;
  }

  //This is for Local test
  const local = "http://192.168.1.8:3001"
  if (url.indexOf(local) > -1) {
    newUri = url.replace(local+"/", "gomouat://");
    if(platform === "android"){
      newUri = url.replace(local, "gomouat://app");
    }
  }

  return newUri
}

const OuterGrid = styled.div`
  margin: ${remCalc(24)} 0;
  padding: 0 ${remCalc(24)};
  position: relative;
  height: ${remCalc(440)};
`;

const AppRedirect = memo(function renderRedirect({ url }) {
  return (
    <Content>
      <Heading level={4} >
        If you are not redirected back to GOMO app, click
      <Anchor
          href={getAppUri(url, getPlatform(navigator.userAgent))}
        >
          {` here`}
        </Anchor>
      </Heading>
    </Content>
  )
})

function App() {
  return (
    <Container>
      <Header type="hybrid" disableAnimation disableLangSelector />
      <Router>
        <Switch>
          <Route exact path="/">
            <AppRedirect url={window.location.href} />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
      <OuterGrid>
        <Row center="sm">
          <Column>
            <StyledHeading level={1}>DOWNLOAD GOMO APP</StyledHeading>
            <Paragraph>
              Download our app to check your usage and add more data anytime,
              anywhere.
          </Paragraph>
          </Column>
        </Row>
        <Row center="sm">
          <AnchorButton
            href="https://itunes.apple.com/sg/app/gomo/id1447453921?mt=8"
            target="_blank"
          >
            <BadgeImage src={AppStoreBadge} alt="appstore-badge" />
          </AnchorButton>
          <AnchorButton
            href="https://play.google.com/store/apps/details?id=com.singtel.gomo.release"
            target="_blank"
          >
            <BadgeImage src={GooglePlayBadge} alt="googleplay-badge" />
          </AnchorButton>
        </Row>
      </OuterGrid>
      <FooterContainer>
        <DownloadPanelImage src={burgerDownloadApp} alt="download-app" />
        <Footer
          type="traveler"
          copyrightLiner="© Singtel (CRN: 199201624D). All Rights Reserved."
        />
      </FooterContainer>
    </Container>
  );
}

export default memo(App);
